<template>
  <div>
    <div class="row">
      <div class="col-lg-3" v-for="ele in publishersData" :key="ele.Publisher">
        <div class="card">
          <div class="position-relative py-3 px-4 text-center">
            <div :class="$style.flag">${{ new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(ele.Spent) }}</div>
            <div class="font-size-70 pt-3 pb-w text-gray-4">
              <i class="fe fe-star"/>
            </div>
            <h5 class="font-size-24 font-weight-bold mb-1">{{ ele.Name }}</h5>
            <div class="font-size-18 text-uppercase mb-3">{{ ele.Country }}</div>
            <div v-if="xml" class="font-weight-bold font-size-18 text-uppercase mb-4">
              <b-link :to="`/${componentType}/publisher/` + ele.Id + '/feeds'" v-if="ele.PubfeedsCount!==0" style="color:rgb(105, 151, 244)">
                {{ ele.PubfeedsCount }}
                <span v-if="ele.PubfeedsCount===1">Feed</span>
                <span v-else>Feeds</span>
              </b-link>
            </div>
            <!-- <div class="border-top pt-3 font-italic">{{ new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(ele.NetClicks) }} net clicks</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'KitGeneral17',
  props: {
    publishersData: Array,
    componentType: String,
    xml: Boolean,
  },
  // methods: {
  //   showFeeds(publisherId) {
  //     this.$router.push('/xml/publisher/' + publisherId + '/feeds')
  //   },
  // },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
